<template>
  <nuxt-link
    v-if="to"
    :to="to"
    :class="classesComp"
  >
    <UiIcon
      v-if="icon"
      :name="icon"
      class="ui-button__icon"
    />

    <slot />
  </nuxt-link>

  <a
    v-else-if="href"
    :href="href"
    :class="classesComp"
  >
    <UiIcon
      v-if="icon"
      :name="icon"
      class="ui-button__icon"
    />

    <slot />
  </a>

  <button
    v-else
    :class="classesComp"
  >
    <UiIcon
      v-if="icon"
      :name="icon"
      class="ui-button__icon"
    />

    <slot />
  </button>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

const props = defineProps<{
  type: 'default' | 'black' | 'outline' | 'black-inverted' | 'black-border' | 'icon',
  size?: 'xs' | 's' | 'm' | 'l' | 'xl',
  isStatic?: boolean,
  loading?: boolean,
  to?: RouteLocationRaw,
  href?: string,
  icon?: string
}>();

const classesComp = computed(() => ({
  'ui-button': true,
  'ui-button_default': props.type === 'default',
  'ui-button_black': props.type === 'black',
  'ui-button_outline': props.type === 'outline',
  'ui-button_black-inverted': props.type === 'black-inverted',
  'ui-button_black-border': props.type === 'black-border',
  'ui-button_icon': props.type === 'icon',
  'ui-button-xs': props.size === 'xs',
  'ui-button-s': props.size === 's',
  'ui-button-m': props.size === 'm',
  'ui-button-l': props.size === 'l',
  'ui-button-xl': props.size === 'xl',
  static: props.isStatic,
  'ui-button_loading': props.loading,
}));
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

@tailwind components;

@layer components {
  .ui-button {
    @apply flex justify-center items-center text-[16px] h-full cursor-pointer rounded-sm transition duration-300;
    font-family: "Open Sans Semibold", sans-serif;

    &_loading {
      @include flowing-animation;
      pointer-events: none;
    }

    &_default {
      @apply bg-[rgba(242,242,242,.4)] text-[#333];
      &:not(.static) {
        &:hover, &:focus {
          background-color: #f2f2f2;
        }
      }
    }

    &_black {
      @apply bg-black text-white;
      &:not(.static) {
        &:hover, &:focus {
          background-color: #505050;
        }
      }
    }

    &_black-inverted {
      @apply border-[1px] border-[#333] text-[#333] bg-transparent;
      &:not(.static) &:not(:disabled){
        &:hover, &:focus {
          background-color: #333;
          color: #fff;
        }
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    &_outline {
      @apply border-[1px] text-[#333] border-[#e5e5e5] opacity-100;
      &:not(.static) {
        &:hover, &:focus {
          opacity: 0.5;
        }
      }
    }

    &_black-border {
      @apply border-[1px] text-[#333] border-[#bdbdbd];
      &:not(.static):hover {
        @apply border-[#090909];
      }
    }

    &-xs {
      height: 34px;
      font-size: 13px;

      @media screen and (min-width: 767px) {
        font-size: 14px;
      }
    }
    &-s {
      height: 40px;
    }

    &-l {
      height: 52px;
    }

    &-m {
      height: 48px;
    }
    &.error {
      border-color: #f00;
      background-color: transparent;
      color: #333;
    }

    &__icon {
      width: 12px;
      height: 12px;
    }

    &_icon {
      width: auto;
      padding: 8px;
      border-radius: 100%;

      &:hover {
        background: #f2f2f2;
      }
    }
  }
}
</style>
